"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DynamicScopeTables = void 0;
require("./DynamicScopeTables.scss");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const InitiativeScopeTable_1 = require("./InitiativeScopeTable");
const ScopeTypeSubsection_1 = require("./ScopeTypeSubsection");
const react_if_1 = require("react-if");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const sonar_core_1 = require("@sonar-software/sonar-core");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const ScopeDisplaySelector_1 = require("./ScopeDisplaySelector");
const table_modifier_1 = require("@ui/table-modifier");
const lib_1 = require("@ui/table-modifier/@arch/lib");
const filters = [
    {
        filterFormat: sonar_core_1.SchemaFormat.Primary,
        filterId: 'Name',
        filterLabel: sonar_ts_constants_1.COPY_SPECIFIC.NAME
    },
    {
        filterFormat: sonar_core_1.SchemaFormat.Owner,
        filterId: 'OwnerIds',
        filterLabel: sonar_ts_constants_1.COPY_SPECIFIC.OWNERS
    }
];
const compactFilters = [filters[0]];
const sortOptions = [
    {
        direction: sonar_ts_types_1.SonarV3SortDirection.Ascending,
        key: 'Name',
        label: sonar_ts_constants_1.COPY_SPECIFIC.NAME
    }
];
const convertToDql = (queryParams) => ({
    filters: (0, lib_1.translateFilterQueryParamsToDynamicQueryLanguage)(queryParams),
    ordering: (0, lib_1.translateOrderQueryParamsToDynamicQueryLanguage)(queryParams)
});
const compactSortOptions = [];
const DynamicScopeTables = ({ counts, initiativeId, queryParams, updateParams }) => {
    var _a, _b;
    const isDataDictionaryEnabled = (0, _hooks_1.useFeatureFlag)(sonar_core_1.FeatureFlag.DataDictionary);
    const [displayType, setDisplayType] = (0, react_1.useState)('comfortable');
    const arrangers = isDataDictionaryEnabled ?
        react_1.default.createElement(ScopeDisplaySelector_1.ScopeDisplaySelector, { onChange: setDisplayType, value: displayType }) :
        null;
    const onlyScopeTypeIsPicklist = counts.length === 1 && sonar_ts_types_1.SonarObjectType.SalesforcePicklistValue === counts[0][0];
    const isCompact = !isDataDictionaryEnabled || displayType === 'compact';
    const selectNamespace = `initiative-${initiativeId};`;
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(table_modifier_1.TableModifier, { className: 'dynamic-scope-table-filters', convertToDynamicQueryLanguage: convertToDql, customArrangers: arrangers, filterDisplayType: 'inline', filters: isCompact || onlyScopeTypeIsPicklist ? compactFilters : filters, filtersString: queryParams.filters, onChange: updateParams, orderString: queryParams.ordering, sonarObjectId: undefined, sonarObjectType: undefined, sortOptions: isCompact ? compactSortOptions : sortOptions }),
        react_1.default.createElement(react_if_1.When, { condition: isCompact },
            react_1.default.createElement(InitiativeScopeTable_1.InitiativeScopeTable, { count: counts.reduce((prev, next) => prev + next[1], 0), dynamicNameFilter: queryParams.filters, initiativeId: initiativeId, nameFilter: (_b = (_a = (0, sonar_ts_lib_1.getValuesFromDynamicFilterString)(filters[0].filterId, queryParams.filters)[0]) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '', selectNamespace: selectNamespace })),
        react_1.default.createElement(react_if_1.When, { condition: isDataDictionaryEnabled && displayType === 'comfortable' }, counts.map(([type, count]) => react_1.default.createElement(ScopeTypeSubsection_1.ScopeTypeSubsection, { count: count, initiativeId: initiativeId, key: type, overrideAutoCollapse: counts.length === 1, queryParams: queryParams, selectNamespace: selectNamespace, type: type })))));
};
exports.DynamicScopeTables = DynamicScopeTables;
