"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AsyncStateAlert = void 0;
const antd_1 = require("antd");
const xuder_1 = require("@sonar-software/xuder");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
function getAlertOptions(options) {
    if (typeof options === 'string') {
        return {
            message: options
        };
    }
    return options;
}
function getNotificationType(status) {
    const stateStatus = status;
    switch (true) {
        case (0, xuder_1.isRequested)(stateStatus):
            return 'info';
        case (0, xuder_1.isErrored)(stateStatus):
        case (0, xuder_1.isFailed)(stateStatus):
            return 'error';
        case (0, xuder_1.isReceived)(stateStatus):
            return 'success';
        default:
            return 'error';
    }
}
const AsyncStateAlert = ({ byStatusCode, errored, failed, received, requested, stateSelector }) => {
    const [alert, setAlert] = (0, react_1.useState)(null);
    const statusCodeOptions = (0, react_1.useMemo)(() => byStatusCode === null || byStatusCode === void 0 ? void 0 : byStatusCode.reduce((codes, entry) => {
        codes[entry.statusCode] = entry.options;
        return codes;
    }, {}), [byStatusCode]);
    (0, _hooks_1.useAsyncProcessing)({
        allowMultipleHandlers: false,
        onStatus: (status, options) => {
            if (options) {
                setAlert(react_1.default.createElement(antd_1.Alert, Object.assign({ type: getNotificationType(status) }, getAlertOptions(options !== null && options !== void 0 ? options : ''))));
            }
        },
        onStatusCode: (_, options) => {
            if (options) {
                setAlert(react_1.default.createElement(antd_1.Alert, Object.assign({}, options)));
            }
        },
        stateSelector,
        statusCodeOptions,
        statusOptions: {
            errored: getAlertOptions(errored),
            failed: getAlertOptions(failed),
            received: getAlertOptions(received),
            requested: getAlertOptions(requested)
        },
        watchAllCalls: true
    });
    return alert;
};
exports.AsyncStateAlert = AsyncStateAlert;
